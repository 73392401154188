import React from "react";
import { Layout, Stack, Main } from "@layout";
import PageTitle from "@components/PageTitle";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import "./pages.css";

const PrivacyNotice = (props) => {
  return (
    <Layout {...props}>
      <Seo title="3air Privacy Policy" />
      <Divider />
      <Stack effectProps={{ effect: "fadeInDown" }}>
        <PageTitle header="Privacy Policy" />
      </Stack>
      <Stack>
        <Main>
          <p>
            3air tech Ltd. (“3air”, “we”, “our” or “us”) collects information
            about you from various sources for the purposes of the distribution
            of 3air Tokens, the entry into contracts and to provide services to
            you, to protect our legitimate interests, to analyze and improve our
            services, to communicate with you, and to comply with our legal and
            regulatory obligations. This Privacy Policy (“Privacy Policy”)
            covers our treatment of the personally identifiable information that
            we gather when you enter into a contractual relationship with 3air
            and when you access or use our Website and any other services
            offered by 3air.
          </p>
          <p>
            From time to time, we may revise or amend this Privacy Policy to
            reflect changes in law, our Personal Data collection and use
            practices, the features on the Services, or advances in technology.
            This Privacy Policy does not cover the practices of entities we do
            not own or control, or people we do not manage.
          </p>
          <p>
            Our Privacy Policy applies to all Services we offer and contracts we
            enter with other parties, but excludes any products, applications or
            services that have separate privacy policies which do not
            incorporate this Privacy Policy. For the purposes of data protection
            laws of the European Union (“EU”), and other relevant jurisdictions
            where Customers are located (“Data Protection Laws”), 3air is a data
            controller (i.e., the company who is responsible for, and controls
            the processing of, your Personal Data).
          </p>
          <p>
            The access and/or use of the Services implies acceptance of this
            Privacy Policy. For the avoidance of doubt the term “Services” shall
            mean all the services provided by 3air, communications between 3air
            and you, contractual relationships between 3air and you, including
            but not limited those related to the sale and distribution of 3air
            Tokens, the 3air Platform and the website https://3air.io, and any
            subdomains, related domains, mobile applications, and all their
            downloadable material and e-mails, and all the services available
            through the Website.
          </p>
          <h2>What data we collect</h2>
          <p>
            To the fullest extent permitted under applicable Data Protection
            Laws, we collect the following data and information (collectively,
            “Personal Data”):
          </p>
          <ol>
            <li>
              Full name, date of birth, gender, country of residence, username,
              passwords, tax numbers, and data included in government-issued
              identification documents;
            </li>
            <li>
              Financial information, sources of wealth or funds or income, level
              of activity anticipated, distributed ledger network wallet
              addresses;
            </li>
            <li>
              Personal Documents such as government-issued IDs, passport, bank
              statements, utility bills, internet bills, income, account
              balances, financial transaction history, credit history, tax
              information, and credit scores, and other forms of identification,
              address verification and source of funds and wealth verification;
            </li>
            <li>
              Corporate information such as legal entity name, doing business as
              name, legal domicile, fiscal domicile, object;
            </li>
            <li>
              Corporate documents such as certificate of registration,
              constitution, memorandum & articles of association, bylaws,
              statues, incumbency certificate, register of directors, register
              of shareholders, register of authorized signatories, registry
              excerpts, financial statements;
            </li>
            <li>
              Information about how you access and use our Services, such as
              your actions on the Services, including your interactions with
              others on the Services, photos, or media you upload to the
              Services, your username, and other Customer Content you provide.
              We may access your device camera roll and camera with your
              permission. If you need to update your permissions, you can do so
              in your device’s settings;
            </li>
            <li>
              Contact lists on your mobile device or in your email accounts so
              that you can identify your contacts on the Services and direct the
              invitation of your contacts. We only access your contact list
              information with your explicit permission;
            </li>
            <li>
              Communications between 3air and you including emails, social media
              messages, job applications, customer support inquiries;
            </li>
            <li>
              Current and historical information concerning your geographic
              location, GPS location, transaction location, and IP addresses
              that you use when you use and/or access our Services. We may use
              third-party services such as the Google Maps API to gather
              information about your location. Google uses various technologies
              to determine your location, including IP address, GPS, and other
              sensors that may, for example, provide Google with information on
              nearby devices, Wi-Fi access points, and cell towers (see Google’s
              Privacy Policy to learn more);
            </li>
            <li>
              Information about your device and its software, such as your IP
              address, browser type, Internet service provider, platform type,
              device type, operating system, date and time stamp, a unique ID
              (that allows us to uniquely identify your browser, mobile device,
              or your account), and other similar information.
            </li>
          </ol>
          <p>
            We may monitor, record, and store your personal information in order
            to protect your safety or the safety of other Customers, comply with
            relevant laws, to assist with regulatory or law enforcement efforts,
            to protect and defend our rights and property, or for other reasons
            relating to our provision of the Services. By using the Services,
            you consent to the recording, storage, and disclosure of such
            information you send or receive for these purposes.
          </p>
          <p>
            The Services are not available to anyone under the age of eighteen
            (18) (“Minors”). 3air has implemented the appropriate controls to
            ensure that Minors do not use or have access to our Services.
            Therefore, 3air will never knowingly and intentionally collect
            Personal Data from Minors. If you are a parent or guardian of a
            Minor and you believe or are aware that a Minor has provided us with
            Personal Data, please contact us immediately. If we become aware
            that we have collected Personal Data from Minors with or without
            verification of parental consent, we will take measures to delete
            that information from our systems.
          </p>
          <h2>How we collect Personal Data</h2>
          <p>
            To the fullest extent permitted under applicable Data Protection
            Laws, we collect Personal Data about you and any other party whose
            details you provide to us when you:
          </p>
          <ol>
            <li>Access or use our Services;</li>
            <li>Enter into a contractual relationship with 3air;</li>
            <li>
              Complete online forms (including call back requests), take part in
              surveys, post on any forums, download information such as white
              papers or other publications or participate in any other
              interactive areas that appear within our Services;
            </li>
            <li>Interact with us using social media;</li>
            <li>
              Provide your contact details to us when registering to use or
              access any services we make available or when you update those
              details; and
            </li>
            <li>Contact us or otherwise connect with us, online or offline.</li>
          </ol>
          <p>
            We will also collect Personal Data where you only partially complete
            and/or abandon any information inputted through our Services, and/or
            other online forms and may use this information to contact you to
            remind you to complete any outstanding information and/or for
            marketing purposes.
          </p>
          <p>
            We may also collect information from your devices (including mobile
            devices) and applications you or your users use to access and use
            our Services (for example, we may collect the device identification
            number and type, location information and connection information
            such as statistics on your page views, traffic to and from the
            Services, referral URL, ad data, your Internet Protocol (IP)
            address, your browsing history and your web log information) and we
            will ask for your permission before we do so. We may do this using
            cookies or similar technologies (as described below).
          </p>
          <p>
            If you intend to give us Personal Data about someone else, you are
            responsible for ensuring that you comply with any obligation and
            consent obligations under applicable Data Protections Laws. Insofar
            as required by applicable Data Protection Laws, you must ensure that
            beforehand you have their explicit consent to do so and that you
            explain to them how we collect, use, disclose and retain their
            Personal Data, or direct them to read our Privacy Policy.
          </p>
          <h2>How we use your Personal Data</h2>
          <p>
            To the fullest extent permitted under applicable under Data
            Protection Laws, we use your information to:
          </p>
          <ol>
            <li>
              Provide any information or Services that you have requested or
              ordered;
            </li>
            <li>
              Compare information for accuracy and to verify it with third
              parties;
            </li>
            <li>Provide, maintain, protect, and improve our Services;</li>
            <li>
              Manage, monitor, and administer your use of the Services and
              provide an enhanced, personal, user experience;
            </li>
            <li>
              Manage our relationship with you (for example, customer services
              and technical support activities);
            </li>
            <li>
              Undertake internal testing of the Services or systems to test and
              improve their security and performance (in these circumstances, we
              would de-identify any information used for such testing purposes);
            </li>
            <li>
              Provide you with any information that we are required to send you
              to comply with our regulatory or legal obligations;
            </li>
            <li>
              Comply with applicable Know Your Customer, Anti-Money Laundering,
              Counter-Terrorism Financing, and Anti-Corruption policies and
              procedures, laws, and regulations;
            </li>
            <li>
              Detect, prevent, investigate, or remediate, crime, illegal or to
              otherwise protect our legal rights (including liaison with
              regulators and law enforcement agencies for these purposes);
            </li>
            <li>
              Contact you to see if you would like to take part in our customer
              research (for example, feedback on your use of our Services);
            </li>
            <li>
              Monitor, carry out statistical analysis and benchmarking (provided
              that in such circumstances it is on an aggregated basis which will
              not be linked back to you or any living individual);
            </li>
            <li>
              Deliver advertising, marketing (including but not limited to
              in-product messaging) or information which may be useful to you;
            </li>
            <li>
              Deliver joint content and services with third parties with whom
              you have a separate relationship (for example, social media
              providers); and
            </li>
            <li>
              In addition to the legal and commercial uses listed above, we may
              be required to provide any and all of your Personal Data to
              governmental authorities as necessary to comply with the law. To
              the extent required by Data Protection Laws, or where we have a
              legitimate and lawful purpose for doing so, we retain information
              about you after your withdrawal of funds through the Services, the
              closure of your Account, if the registration of an Account is
              declined or if you decide not to proceed with the registration of
              an Account.
            </li>
          </ol>
          <p>Our Services may contain technology that enables us to:</p>
          <ol>
            <li>
              Check specific information from your device or systems directly
              relevant to your use of the Services, applications, or Services
              against our records to make sure the Services are being used in
              accordance with our end-user agreements and to troubleshoot any
              problems;
            </li>
            <li>
              Obtain information relating to any technical errors or other
              issues with our Services;
            </li>
            <li>
              Collect information about how you use the features of our
              Services; and
            </li>
            <li>
              Gather statistical information about the operating system and
              environment from which you access our Services.
            </li>
          </ol>
          <p>
            If you become a follower in 3air's social networks, the processing
            of Personal Data will be governed by the policies of the Social
            Network, as well as by their terms of use, privacy policies and
            regulations that belong to the social network that corresponds in
            each case and that you have previously accepted.
          </p>
          <p>
            3air will treat your Personal Data in order to correctly manage your
            presence in the social network, inform him of 3air activities,
            Services, as well as for any other purpose that the rules of the
            social networks allow.
          </p>
          <p>
            In no case will 3air use the profiles of followers in social
            networks to send advertising individually.
          </p>
          <h2>Parties with whom we may share your Personal Data</h2>
          <p>We may share your Personal Data with:</p>
          <ol>
            <li>
              Third-Party Internet and Marketing Services Vendors such as Active
              Campaign, Facebook, Discord, Telegram, GoogleCloud, Google
              Analytics, FireBase Cloud, FireBase Hosting;
            </li>
            <li>
              Government and State Authorities to respond to subpoenas, court
              orders, legal process, law-enforcement requests, legal claims, or
              government inquiries and to protect and defend the rights,
              interests, safety, and security of 3air, our affiliates,
              Customers, or the public;
            </li>
            <li>
              Our parent companies, subsidiaries, affiliates, agents,
              representatives, predecessors, advisors, and the successors and
              assigns of each of the foregoing;
            </li>
            <li>
              Third parties in connection to corporate transactions such as the
              sale of a website, a merger, consolidation, reorganization,
              financing, change or control or acquisition of all or a portion of
              our business by another company or third party, asset sale,
              initial public offering, or in the unlikely event of bankruptcy or
              similar proceeding.
            </li>
          </ol>
          <h2>Data Retention</h2>
          <p>
            We will retain your Personal Data for as long as it is necessary for
            the purposes of performing a contract, comply with our legal and
            regulatory obligations, and protecting our legitimate interests.
            Specifically, we reserve the right to retain your Personal Data for
            the purposes of complying with applicable Know Your Customer,
            Anti-Money Laundering, Counter-Terrorism Financing, and
            Anti-Corruption laws and regulations for a period of at least seven
            (7) years, and for the purposes of legal proceedings, complaints,
            and disputes for as long as this is necessary to protect our
            legitimate interests.
          </p>
          <h3>Persons who may access your Personal Data</h3>
          <p>
            3air’s authorized personnel, including but not limited to our
            Compliance Officers, shall have access to your Personal Data on a
            need-to-know basis. Our Compliance Officers and other authorized
            personnel are bound to confidentiality and non-disclosure
            agreements, and subject to strict company policies related to the
            access and use of the data.
          </p>
          <p>
            We may engage from time to time to third-party service providers to
            assist 3air with the implementation of the KYC/AML & CTF Policy as
            well as the compliance with applicable Know Your Customer,
            Anti-Money Laundering, Counter-Terrorism Financing, and
            Anti-Corruption laws and regulations. Where applicable, we will
            enter into a data processing agreement with these third parties to
            ensure data security and protection of your Personal Data against
            Data Beaches. Such Data Processors will only process your Personal
            Data to the extent required for the provision of services for which
            they are engaged.
          </p>
          <p>
            We may provide your Personal Data to competent authorities upon
            their request to the extent legally required or to the extent
            necessary to defend our rights in legal proceedings or
            investigations.
          </p>
          <h2>Data Security</h2>
          <p>
            To protect your Personal Data, 3air takes all reasonable precautions
            and follows the best practices of the industry to prevent the loss,
            misuse, improper access, disclosure, alteration, or destruction of
            the same.
          </p>
          <p>
            In addition to the purposes described in this section, we may also
            use information we gather to deliver targeted and interest-based
            advertising, marketing (including in-product messaging) or
            information to you which may be useful, based on your use of the
            Services or any other information we have about you (depending on
            the Services, you may be able to configure these features to suit
            your preferences).
          </p>
          <h3>Legal basis for processing in the EU</h3>
          <p>
            In the EU, we collect your Personal Data for the following purposes:
          </p>
          <ol>
            <li>
              Based on your consent, for marketing purposes, which can be
              revoked at any time;
            </li>
            <li>
              Where necessary to perform any contract we enter into, or have
              entered into, with you to provide Services or provide access to
              our Services;
            </li>
            <li>
              Where necessary for our legitimate business interests (or those of
              a third party) when your interests and fundamental rights do not
              override those interests; and
            </li>
            <li>
              Where we need to comply with a legal or regulatory obligation
              either in the EU or elsewhere.
            </li>
          </ol>
          <p>You have the following rights:</p>
          <ol>
            <li>The right to access your own Personal Data;</li>
            <li>
              The right to have your Personal Data rectified if it is inaccurate
              or incomplete;
            </li>
            <li>
              The right to request deletion or removal of your Personal Data
              where there is no good reason for processing to continue;
            </li>
            <li>
              The right to restrict processing of your Personal Data where there
              is no good reason for processing to continue;
            </li>
            <li>
              The right to data portability to enable moving, copying or
              transferring of Personal Data from one platform to another;
            </li>
            <li>
              The right to object to the processing of your Personal Data in
              certain circumstances; and
            </li>
            <li>
              Rights relating to profiling and automated decision making
              resulting from the processing of your Personal Data.
            </li>
          </ol>
          <p>
            The exercise of these rights is personal and therefore must be
            exercised directly by the interested party, requesting it directly
            to 3air, which means that any Customer, user, subscriber or
            collaborator who has provided their data at any time can contact
            3air and request information about the data that it has stored and
            how it has been obtained, request the rectification of the same,
            request the portability of your Personal Data, oppose the
            processing, limit its use or request the cancellation of that data
            in 3air's files.
          </p>
          <p>
            To exercise the rights of access, rectification, cancellation,
            portability and opposition, you must send an email to the Data
            Protection Officer of 3air: compliance@3air.io, together with a
            valid proof of identity such as a Government-issued ID document.
          </p>
          <h3>Content from other Services</h3>
          <p>
            The Services may include embedded content (for example, videos,
            images, articles, etc.). The embedded content of other internet
            websites behaves in exactly the same way as if you had visited other
            internet websites.
          </p>
          <p>
            These internet websites may collect data about you, use cookies,
            embed an additional third-party tracking code, and monitor your
            interaction using this code.
          </p>
          <h2>Navigation</h2>
          <p>
            When accessing and/or using the Services, non-identifying data may
            be collected, which may include the IP address, geolocation, a
            record of how services and Services are used, browsing habits and
            other data that cannot be used to identify the User.
          </p>
          <p>
            The Services use the following third-party analysis services: Google
            Analytics
          </p>
          <p>
            3air uses the information obtained to obtain statistical data,
            analyze trends, administer the Services, study navigation patterns
            and to gather demographic information.
          </p>
          <h2>Accuracy and veracity of Personal Data</h2>
          <p>
            You agree that the information provided to 3air is correct,
            complete, accurate and current. You are solely responsible for the
            veracity and correctness of the data you submit when access and/or
            using the Services, exonerating 3air from any responsibility in this
            regard.
          </p>
          <h2>Acceptance and consent</h2>
          <p>
            You declare to have been informed of the conditions on protection of
            Personal Data, You accept and consent to the treatment of the same
            by 3air in the manner and for the purposes indicated in this Privacy
            Policy.
          </p>
          <h2>Revocability</h2>
          <p>
            To exercise the rights of access, rectification, cancellation,
            portability, and opposition, you must send an email to
            compliance@3air.io along with your valid proof of ID such as a
            Government-issued identification document. The exercise of your
            rights do not include any Personal Data that 3air is obliged to keep
            for administrative, performance of contracts, legal or security
            purposes.
          </p>
          <h2>Change of Operatorship</h2>
          <p>
            In case of incorporation, acquisition, merger, or any other causes
            that cause the change of Operatorship of the Services, you expressly
            consent that your registration data and information are transferred
            by 3air to the new Operator. When and if this occurs, 3air will
            comply, in any case, with the duty of information to you.
          </p>
          <h2>Changes to the Privacy Policy</h2>
          <p>
            3air reserves the right to modify this Privacy Policy to adapt it to
            legislative developments, as well as to industry practices. 3air may
            notify you of changes to this Privacy Policy by email or through the
            Services.
          </p>
          <h2>Services Operator</h2>
          <p>
            The Services are operated by 3air tech Ltd., a BVI Business Company
            formed under the BVI Business Companies Act, 2004, (As amended) of
            the British Virgin Islands with BVI Business Company Number 2075729
            and having its registered office at Intershore Chambers, PO Box
            4342, Road Town, Tortola, VG1110 British Virgin Islands
          </p>
          <h2>Contact</h2>
          <p>
            In case you have any questions or complaints about the Privacy
            Policy, you can contact our data protection officer via email to
            compliance@3air.io.
          </p>
        </Main>
      </Stack>
    </Layout>
  );
};

export default PrivacyNotice;
